import { TabbedFormViewProps, TabbedForm, TextInput, maxLength } from 'react-admin';

export default function MerchantConfigTab(props: TabbedFormViewProps): JSX.Element {
  return (
    <TabbedForm.Tab label="Merchant Config" {...props}>
      <TextInput label="Type" source="attributes.type" validate={maxLength(255)} />
      <TextInput label="Username" source="attributes.username" validate={maxLength(50)} />
      <TextInput label="Feed" source="attributes.feed" validate={maxLength(255)} />
    </TabbedForm.Tab>
  );
}
