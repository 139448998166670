import { Create } from 'react-admin';
import CategoryForm from './Form';

export default function CategoryCreate(): JSX.Element {
  return (
    <Create>
      <CategoryForm />
    </Create>
  );
}
