import { MerchantResource } from '../../Entities/Merchant';
import { RegistrationResource } from '../../Entities/Registration';
import { DeepPartial, entry } from '../../utils';

export const REGISTRATION_STATUS_CHOICES = [
  entry('PROSPECT-Proposal'),
  entry('PROSPECT-In Negotiation'),
  entry('PROSPECT-Closed Lost'),
  entry('LEAD-Qualified Lead'),
  entry('LEAD-Unqualified'),
  entry('CUSTOMER-Closed Won'),
  entry('CUSTOMER-Lost Customer'),
  entry('CUSTOMER-Renewal'),
  entry('CUSTOMER-Prospect'),
  entry('CUSTOMER-SignedUp'),
];

export const REGISTRATION_STATUS_DEFAULTS = [
  'PROSPECT-Proposal',
  'PROSPECT-In Negotiation',
  'LEAD-Qualified Lead',
  'CUSTOMER-Closed Won',
  'CUSTOMER-Renewal',
  'CUSTOMER-Prospect',
  'CUSTOMER-SignedUp',
];

export function registrationToMerchant(
  record: RegistrationResource,
): DeepPartial<MerchantResource> {
  const abn = record.attributes.abn ? Number(record.attributes.abn) : undefined;

  return {
    attributes: {
      abn,
      address: record.attributes.address,
      agency_email: record.attributes.agency_email,
      agency_name: record.attributes.agency_name,
      agency_phone_number: record.attributes.agency_phone_number,
      city: record.attributes.city,
      company_name: record.attributes.company_name,
      description: record.attributes.description,
      email: record.attributes.email,
      finance_email: record.attributes.finance_email,
      finance_phone_number: record.attributes.finance_phone_number,
      marketing_phone_number: record.attributes.marketing_phone_number,
      merchant_name: record.attributes.merchant_name,
      phone_number: record.attributes.phone_number,
      postal_code: record.attributes.postal_code,
      primary_contact: record.attributes.primary_contact,
      salesperson: record.attributes.salesperson,
      username: record.attributes.username,
      website: record.attributes.website,
    },
    relationships: {
      registration: { data: { id: record.id, type: 'registrations' } },
      region: record.relationships?.region,
      merchant_status: { data: { id: '0', type: 'merchant_statuses' } },
    },
  };
}
