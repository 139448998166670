import { Box, Button, Card, CardContent, Divider, Stack, Switch } from '@mui/material';
import {
  Labeled,
  PreferenceKeyContextProvider,
  RecordContextProvider,
  Title,
  useTheme,
  useResetStore,
} from 'react-admin';
import { AccessTime, DarkMode, PermIdentity, SettingsSuggestTwoTone } from '@mui/icons-material';
import TzDatePreferencesEditor from '../Components/TzDate/TzDatePreferencesEditor';
import AssumedPermissionsPreferencesEditor from '../Components/AssumedPermissionsPreferencesEditor';
import TzDateField from '../Components/TzDate/TzDateField';
import SadPanda from '../Components/SadPanda';

function PreferenceSectionHeader({
  icon,
  title,
}: {
  icon: JSX.Element;
  title: string;
}): JSX.Element {
  return (
    <span style={{ display: 'inline-flex' }}>
      {icon}
      <span style={{ marginLeft: '8px' }}>{title}</span>
    </span>
  );
}

export default function Preferences(): JSX.Element {
  const resetStore = useResetStore();
  const [theme, setTheme] = useTheme();

  return (
    <>
      <Title title="Preferences" />
      <Card style={{ marginTop: '8px' }}>
        <CardContent>
          <Stack spacing={2} divider={<Divider />}>
            <span style={{ display: 'inline-flex' }}>
              <Button onClick={resetStore} style={{ marginLeft: '8px' }}>
                Reset All Preferences
              </Button>
            </span>
            <Box>
              <PreferenceSectionHeader title="Theme Preferences" icon={<DarkMode />} />
              <br />
              <Switch
                checked={theme === 'light'}
                onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
              />
              Light/Dark Mode
            </Box>
            <PreferenceKeyContextProvider value="preferences.tzDatePreferences">
              <PreferenceSectionHeader icon={<AccessTime />} title="Date/Time Preferences" />
              <RecordContextProvider value={{ now: new Date().toISOString() }}>
                <Labeled label="Example Date/Time">
                  <TzDateField showDate showTime source="now" />
                </Labeled>
              </RecordContextProvider>
              <TzDatePreferencesEditor />
            </PreferenceKeyContextProvider>
            <Box>
              <PreferenceSectionHeader icon={<PermIdentity />} title="Assumed Permissions" />
              <AssumedPermissionsPreferencesEditor />
            </Box>
            <Box>
              <PreferenceSectionHeader
                icon={<SettingsSuggestTwoTone />}
                title="List Page Preferences"
              />
              <SadPanda />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
