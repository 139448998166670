import { Box } from '@mui/material';
import { DeleteButton, SaveButton, Toolbar } from 'react-admin';
import CancelButton from './CancelButton';

export type EditToolbarProps = {
  disableDelete?: boolean;
};

export default function EditToolbar({ disableDelete = false }: EditToolbarProps): JSX.Element {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <SaveButton />
        <CancelButton sx={{ marginLeft: '2em' }} />
      </Box>
      {disableDelete ? null : <DeleteButton />}
    </Toolbar>
  );
}
