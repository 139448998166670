import {
  ArrayInput,
  SimpleFormIterator,
  TabbedForm,
  TabbedFormViewProps,
  TextInput,
} from 'react-admin';

function validateKeywordsLength(keywords: string[] | undefined): string | undefined {
  const totalKeywords = keywords?.length ?? 0;
  const totalSeparators = totalKeywords <= 1 ? 0 : totalKeywords - 1;
  const totalLength = keywords?.reduce((acc, v) => acc + v.length, totalSeparators) ?? 0;

  return totalLength > 200
    ? 'Max keyword data reached (200 characters, including separators))'
    : undefined;
}

export default function KeywordsTab(props: TabbedFormViewProps): JSX.Element {
  return (
    <TabbedForm.Tab label="Keywords" {...props}>
      <ArrayInput label="Keywords" source="attributes.keywords" validate={validateKeywordsLength}>
        <SimpleFormIterator>
          <TextInput source="" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </TabbedForm.Tab>
  );
}
