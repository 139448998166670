import { List as ListIcon } from '@mui/icons-material';
import { Menu } from '@react-admin/ra-rbac';
import {
  LayoutProps,
  Layout as RALayout,
  ResourceDefinitions,
  useResourceDefinitions,
} from 'react-admin';
import { capitalize } from 'lodash';
import AppBar from './AppBar';

function PermissionedMenu(): JSX.Element {
  const resources: ResourceDefinitions = useResourceDefinitions();

  return (
    <Menu>
      <Menu.DashboardItem />
      {Object.keys(resources).map((key) => {
        const resource = resources[key];

        if (!resource.hasList) {
          return null;
        }

        const Icon = resource.icon ?? ListIcon;

        return (
          <Menu.Item
            key={resource.name}
            to={`/${resource.name}`}
            action="list"
            leftIcon={<Icon />}
            primaryText={resource?.options?.label ?? capitalize(resource.name)}
          />
        );
      })}
    </Menu>
  );
}

export default function Layout(props: LayoutProps): JSX.Element {
  return <RALayout {...props} appBar={AppBar} menu={PermissionedMenu} />;
}
