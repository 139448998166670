import { Datagrid, ReferenceField, TextField } from 'react-admin';
import TzDateField from '../TzDate/TzDateField';
import ListWidget from './ListWidget';

export default function LatestRemarksWidget(): JSX.Element {
  return (
    <ListWidget title="Latest Remarks" resource="merchant_remarks">
      <Datagrid bulkActionButtons={false}>
        <TzDateField showDate label="Date" source="attributes.date" />
        <ReferenceField
          label="Merchant"
          source="relationships.merchant.data.id"
          reference="merchants"
        >
          <TextField source="attributes.merchant_name" />
        </ReferenceField>
        <TextField label="Created By" source="attributes.created_by" />
        <TextField label="Title" source="attributes.title" />
      </Datagrid>
    </ListWidget>
  );
}
