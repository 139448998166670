import { Permissions } from '@react-admin/ra-rbac';
import { Auth0AuthProvider, httpClient as auth0HttpClient } from 'ra-auth-auth0';
import { Auth0Client } from '@auth0/auth0-spa-js';

const claimsNamespace = 'https://claims.cms.getprice.com.au/permissions';

// Actions taken from https://react-admin-ee.marmelab.com/documentation/ra-rbac#vocabulary
const ReadManyActions = ['list', 'show', 'export'];
const ReadOneActions = ['read'];
const WriteManyActions = ['create', 'edit', 'delete', 'clone'];
const WriteOneActions = ['write'];

const auth0 = new Auth0Client({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  },
});

const auth0AuthProvider = Auth0AuthProvider(auth0, {
  loginRedirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URL,
  logoutRedirectUri: import.meta.env.VITE_LOGOUT_REDIRECT_URL,
});

export function expandPermissions(permissions: string[]): Permissions {
  return permissions.flatMap((permission): Permissions => {
    if (permission === 'admin:api') {
      return [{ resource: '*', action: '*' }];
    }

    const [action, resource] = permission.split(':');

    if (action === 'read') {
      // Resource-level permissions
      return [
        { resource, action: ReadManyActions },
        { resource: `${resource}.tab.*`, action: ReadOneActions },
        { resource: `${resource}.attributes.*`, action: ReadOneActions },
      ];
    }

    if (action === 'write') {
      // Resource-level permissions
      return [
        { resource, action: WriteManyActions },
        { resource: `${resource}.tab.*`, action: WriteOneActions },
        { resource: `${resource}.attributes.*`, action: WriteOneActions },
      ];
    }

    return [];
  });
}

export function collapsePermissions(permissions: Permissions): string[] {
  return permissions
    .flatMap((p) =>
      Array.isArray(p.resource) ? p.resource.map((r) => ({ ...p, resource: r })) : [p],
    )
    .filter((p) => !(p.resource as string).match(/\./g))
    .map((p) => (Array.isArray(p.action) ? p : { ...p, action: [p.action] }))
    .map((p) => {
      if (p.action.includes('*') && p.resource === '*') {
        return 'admin:api';
      }

      if (p.action.includes('list')) {
        return `read:${p.resource}`;
      }

      return `write:${p.resource}`;
    })
    .sort();
}

export type GetPermissionsOptions = Record<string, unknown> & { forceRefresh?: boolean };
export const AuthProvider = {
  ...auth0AuthProvider,
  getPermissions: async (
    options: GetPermissionsOptions = { forceRefresh: false },
  ): Promise<Permissions> => {
    const assumed = localStorage.getItem('RaStore.preferences.assumedPermissions');

    if (assumed && !options.forceRefresh) {
      return expandPermissions(JSON.parse(assumed));
    }

    const cached = localStorage.getItem('RaStore.permissions');

    if (cached && cached !== '[]' && !options.forceRefresh) {
      return expandPermissions(JSON.parse(cached));
    }

    const claims = await auth0.getIdTokenClaims();

    if (claims && Object.prototype.hasOwnProperty.call(claims, claimsNamespace)) {
      const permissions = expandPermissions(claims[claimsNamespace]);

      if (!cached || cached === '[]' || options.forceRefresh) {
        localStorage.setItem('RaStore.permissions', JSON.stringify(claims[claimsNamespace]));
      }

      return permissions;
    }

    return [];
  },
};

export const httpClient = auth0HttpClient(auth0);
