import { canAccess, List, Permissions } from '@react-admin/ra-rbac';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  BulkUpdateButton,
  DatagridConfigurable,
  FunctionField,
  ImageField,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
  usePermissions,
} from 'react-admin';
import { BulkUpdateFormButton } from '@react-admin/ra-form-layout';
import { Check, Clear } from '@mui/icons-material';
import TzDateField from '../../Components/TzDate/TzDateField';
import { IMAGE_STATUS_CHOICES } from './utils';
import TzDateTimeInput from '../../Components/TzDate/TzDateTimeInput';
import { OfferResource } from '../../Entities/Offer';
import {
  DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT,
  DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH,
  DEFAULT_LIST_PAGE_PAGINATION_LIMIT,
} from '../../utils';
import TruncatedTextField from '../../Components/TruncatedTextField';
import LargePagination from '../../Components/LargePagination';
import TextArrayInput from '../../Components/TextArrayInput';

const filters = (permissions: Permissions): JSX.Element[] =>
  [
    <TextInput key="id" label="ID" source="id" alwaysOn />,
    <TextInput key="name" label="Name" source="contains.name" alwaysOn />,
    canAccess({ permissions, action: 'list', resource: 'merchants' }) && (
      <ReferenceArrayInput
        key="merchant_status"
        label="Merchant Status"
        source="any.merchant.merchant_status.id"
        reference="merchant_statuses"
        target="id"
        alwaysOn
      >
        <AutocompleteArrayInput label="Merchant Status" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    canAccess({ permissions, action: 'list', resource: 'merchants' }) &&
      canAccess({ permissions, action: 'list', resource: 'regions' }) && (
        <ReferenceArrayInput
          key="merchant_region"
          label="Merchant Region"
          source="any.merchant.region.id"
          reference="regions"
          target="id"
          alwaysOn
        >
          <AutocompleteArrayInput label="Merchant Region" optionText="attributes.name" />
        </ReferenceArrayInput>
      ),
    <TextInput key="manufacturer" label="Manufacturer" source="contains.manufacturer" />,
    canAccess({ permissions, action: 'list', resource: 'merchants' }) && (
      <ReferenceArrayInput
        key="merchants"
        label="Merchants"
        source="any.merchant.id"
        reference="merchants"
        target="id"
      >
        <AutocompleteArrayInput
          label="Merchants"
          optionText="attributes.merchant_name"
          filterToQuery={(query: string) => ({ contains: { merchant_name: query } })}
        />
      </ReferenceArrayInput>
    ),
    canAccess({ permissions, action: 'list', resource: 'merchants' }) && (
      <ReferenceArrayInput
        key="excluded_merchants"
        label="Exclude Merchants"
        source="not.any.merchant.id"
        reference="merchants"
        target="id"
      >
        <AutocompleteArrayInput
          label="Exclude Merchants"
          optionText="attributes.merchant_name"
          filterToQuery={(query: string) => ({ contains: { merchant_name: query } })}
        />
      </ReferenceArrayInput>
    ),
    canAccess({ permissions, action: 'list', resource: 'products' }) && (
      <ReferenceArrayInput
        key="products"
        label="Products"
        source="any.product.id"
        reference="products"
        target="id"
      >
        <AutocompleteArrayInput
          label="Products"
          optionText="attributes.name"
          filterToQuery={(query: string) => ({ contains: { name: query } })}
        />
      </ReferenceArrayInput>
    ),
    canAccess({ permissions, action: 'list', resource: 'products' }) && (
      <NullableBooleanInput key="has_product" label="Has Product?" source="not.isNull.product" />
    ),
    canAccess({ permissions, action: 'list', resource: 'categories' }) && (
      <ReferenceArrayInput
        key="categories"
        label="Categories"
        source="any.category.id"
        reference="categories"
        target="id"
      >
        <AutocompleteArrayInput
          label="Categories"
          optionText="attributes.full_name"
          filterToQuery={(query: string) => ({ contains: { name: query } })}
        />
      </ReferenceArrayInput>
    ),
    canAccess({ permissions, action: 'list', resource: 'categories' }) && (
      <NullableBooleanInput key="has_category" label="Has Category?" source="not.isNull.category" />
    ),
    <TextInput key="model" label="Model" source="contains.model" />,
    <TextInput key="description" label="Description" source="contains.description" />,
    <TextInput key="mpn" label="MPN" source="contains.mpn" />,
    <TextInput key="gtin" label="GTIN" source="gtin" />,
    <TextInput
      key="external_category"
      label="Category (From Feed) Equals"
      source="equals.external_category"
    />,
    <TextArrayInput
      key="external_category_contains"
      label="Category (From Feed) Contains"
      source="contains.external_category"
    />,
    <TextArrayInput
      key="external_category_not_contains"
      label="Category (From Feed) Does Not Contain"
      source="not.contains.external_category"
    />,
    <TextInput
      key="external_manufacturer"
      label="Manufacturer (From Feed)"
      source="contains.external_manufacturer"
    />,
    <TextInput key="site_id" label="Site ID" source="site_id" />,
    <NullableBooleanInput key="is_in_stock" label="In Stock?" source="is_in_stock" />,
    <NumberInput
      key="current_price_lt"
      label="Current Price Less Than"
      source="lessThan.current_price"
    />,
    <NumberInput
      key="current_price_gt"
      label="Current Price Greater Than"
      source="greaterThan.current_price"
    />,
    <NumberInput
      key="normal_price_lt"
      label="Normal Price Less Than"
      source="lessThan.normal_price"
    />,
    <NumberInput
      key="normal_price_gt"
      label="Normal Price Greater Than"
      source="greaterThan.normal_price"
    />,
    <NumberInput
      key="shipping_cost_lt"
      label="Shipping Cost Less Than"
      source="lessThan.shipping_cost"
    />,
    <NumberInput
      key="shipping_cost_gt"
      label="Shipping Cost Greater Than"
      source="greaterThan.shipping_cost"
    />,
    <TextInput key="url" label="URL" source="contains.raw_url" />,
    <TzDateTimeInput key="starts_at_before" label="Starts Before" source="lessThan.starts_at" />,
    <TzDateTimeInput key="starts_at_after" label="Starts After" source="greaterThan.starts_at" />,
    <TzDateTimeInput key="ends_at" label="Ends After" source="greaterThan.ends_at" />,
    <TzDateTimeInput key="ends_at" label="Ends Before" source="lessThan.ends_at" />,
    <NullableBooleanInput key="is_approved" label="Approved?" source="is_approved" />,
    <NullableBooleanInput key="is_adult" label="For Adult Audiences?" source="is_adult" />,
    <NullableBooleanInput
      key="is_auto_categorized"
      label="Is Auto/AI Categorized?"
      source="is_auto_categorized"
    />,
    <TzDateTimeInput key="created_at_before" label="Created Before" source="lessThan.created_at" />,
    <TzDateTimeInput
      key="created_at_after"
      label="Created After"
      source="greaterThan.created_at"
    />,
    <TzDateTimeInput key="edited_at_before" label="Edited Before" source="lessThan.edited_at" />,
    <TzDateTimeInput key="edited_at_after" label="Edited After" source="greaterThan.edited_at" />,
  ].filter((value) => typeof value !== 'boolean');

function OfferBulkActionButtons({ permissions }: { permissions: Permissions }): JSX.Element {
  return (
    <>
      {canAccess({ permissions, action: 'list', resource: 'products' }) &&
        canAccess({ permissions, action: 'write', resource: 'offers' }) && (
          <BulkUpdateButton
            label="Clear Product"
            icon={<Clear />}
            data={{ relationships: { product: { data: null } } }}
          />
        )}
      {canAccess({ permissions, action: 'list', resource: 'categories' }) &&
        canAccess({ permissions, action: 'write', resource: 'offers' }) && (
          <BulkUpdateButton
            label="Clear Category"
            icon={<Clear />}
            data={{ relationships: { category: { data: null } } }}
          />
        )}
      {canAccess({ permissions, action: 'write', resource: 'offers' }) && (
        <BulkUpdateButton
          label="Approve"
          icon={<Check />}
          data={{ attributes: { is_approved: true } }}
        />
      )}
      {canAccess({ permissions, action: 'write', resource: 'offers' }) && (
        <BulkUpdateFormButton>
          <SimpleForm
            defaultValues={{
              relationships: {
                category: { data: { type: 'categories' } },
                product: { data: { type: 'products' } },
              },
            }}
          >
            {canAccess({ permissions, action: 'list', resource: 'products' }) && (
              <ReferenceInput source="relationships.product.data.id" reference="products">
                <AutocompleteInput
                  fullWidth
                  label="Product"
                  optionText="attributes.name"
                  filterToQuery={(query: string) =>
                    query.match(/^\d+$/)
                      ? { or: { equals: { id: query }, contains: { name: query } } }
                      : { contains: { name: query } }
                  }
                />
              </ReferenceInput>
            )}
            {canAccess({ permissions, action: 'list', resource: 'categories' }) && (
              <ReferenceInput source="relationships.category.data.id" reference="categories">
                <AutocompleteInput
                  fullWidth
                  label="Category"
                  optionText="attributes.full_name"
                  filterToQuery={(query: string) =>
                    query.match(/^\d+$/)
                      ? { or: { equals: { id: query }, contains: { name: query } } }
                      : { contains: { name: query } }
                  }
                />
              </ReferenceInput>
            )}
            <NullableBooleanInput source="attributes.is_approved" label="Approved?" />
            <NullableBooleanInput source="attributes.is_adult" label="For Adult Audiences?" />
          </SimpleForm>
        </BulkUpdateFormButton>
      )}
    </>
  );
}

export default function OfferList(): JSX.Element {
  const { permissions } = usePermissions();

  const filterDefaultValues: Record<
    string,
    Record<string, Record<string, Record<string, string>>>
  > = {
    // Default filters: Live merchants in Australia
    any: {},
  };
  if (canAccess({ permissions, action: 'list', resource: 'merchants' })) {
    filterDefaultValues.any = {
      ...filterDefaultValues.any,
      merchant: { merchant_status: { id: '1' } },
    };

    if (canAccess({ permissions, action: 'list', resource: 'regions' })) {
      filterDefaultValues.any.merchant = {
        ...filterDefaultValues.any.merchant,
        region: { id: '1' },
      };
    }
  }

  return (
    <List
      filters={filters(permissions)}
      perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT}
      pagination={<LargePagination />}
      sort={{ field: 'id', order: 'DESC' }}
      filterDefaultValues={filterDefaultValues}
    >
      <DatagridConfigurable
        bulkActionButtons={<OfferBulkActionButtons permissions={permissions} />}
        omit={[
          'attributes.created_at',
          'attributes.currency',
          'attributes.description',
          'attributes.edited_at',
          'attributes.ends_at',
          'attributes.external_category',
          'attributes.external_image_last_download_attempts',
          'attributes.external_image_last_downloaded_at',
          'attributes.external_image_url',
          'attributes.external_manufacturer',
          'attributes.gtin',
          'attributes.image_status',
          'attributes.is_adult',
          'attributes.is_auto_categorized',
          'attributes.manufacturer',
          'attributes.merchant_name',
          'attributes.model',
          'attributes.mpn',
          'attributes.normal_price',
          'attributes.url',
          'attributes.shipping_cost',
          'attributes.site_id',
          'attributes.starts_at',
        ]}
      >
        <TextField label="ID" source="id" />
        <ImageField
          label="Image"
          sortable={false}
          source="attributes.image_url"
          sx={{
            '& img': {
              maxHeight: DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT,
              maxWidth: DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH,
            },
          }}
        />
        <ImageField
          label="Image (From Feed)"
          sortable={false}
          source="attributes.external_image_url"
        />
        <TextField label="Manufacturer" source="attributes.manufacturer" />
        <TruncatedTextField length={40} label="Name" source="attributes.name" />
        {canAccess({ permissions, action: 'list', resource: 'merchants' }) && (
          <ReferenceField
            label="Merchant"
            source="relationships.merchant.data.id"
            reference="merchants"
          >
            <TextField source="attributes.merchant_name" />
          </ReferenceField>
        )}
        {canAccess({ permissions, action: 'list', resource: 'products' }) && (
          <ReferenceField
            emptyText="Not assigned"
            label="Product"
            source="relationships.product.data.id"
            reference="products"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        {canAccess({ permissions, action: 'list', resource: 'categories' }) && (
          <ReferenceField
            emptyText="Not assigned"
            label="Category"
            source="relationships.category.data.id"
            reference="categories"
          >
            <TextField source="attributes.full_name" />
          </ReferenceField>
        )}
        <TextField label="Model" source="attributes.model" />
        <TextField label="Description" source="attributes.description" />
        <TextField label="MPN" source="attributes.mpn" />
        <TextField label="GTIN" source="attributes.gtin" />
        <TextField label="Category (From Feed)" source="attributes.external_category" />
        <TextField label="Manufacturer (From Feed)" source="attributes.external_manufacturer" />
        <BooleanField label="In Stock?" source="attributes.is_in_stock" />
        <TextField label="Site ID" source="attributes.site_id" />
        <FunctionField
          label="Current Price"
          source="attributes.current_price"
          render={(record?: Partial<OfferResource>, source?: string) =>
            record &&
            source && (
              <NumberField
                source={source}
                options={{ style: 'currency', currency: record?.attributes?.currency ?? 'AUD' }}
              />
            )
          }
        />
        <FunctionField
          label="Normal Price"
          source="attributes.normal_price"
          render={(record?: Partial<OfferResource>, source?: string) =>
            record &&
            source && (
              <NumberField
                source={source}
                options={{ style: 'currency', currency: record?.attributes?.currency ?? 'AUD' }}
              />
            )
          }
        />
        <FunctionField
          label="Shipping Cost"
          source="attributes.shipping_cost"
          render={(record?: Partial<OfferResource>, source?: string) =>
            record &&
            source && (
              <NumberField
                source={source}
                options={{ style: 'currency', currency: record?.attributes?.currency ?? 'AUD' }}
              />
            )
          }
        />
        <TextField label="Currency" source="attributes.currency" />
        <UrlField label="URL" source="attributes.url" />
        <TzDateField
          label="Image (From Feed) / Last Downloaded At"
          source="attributes.external_image_last_downloaded_at"
          showTime
        />
        <TextField
          label="Image (From Feed) / Last Download Attempt Count"
          source="attributes.external_image_last_download_attempts"
        />
        <SelectField
          label="Image (From Feed) / Image Download Status"
          source="attributes.image_status"
          choices={IMAGE_STATUS_CHOICES}
        />
        <TzDateField label="Start Time" source="attributes.starts_at" showTime />
        <TzDateField label="End Time" source="attributes.ends_at" showTime />
        <BooleanField label="Approved?" source="attributes.is_approved" />
        <BooleanField label="Adult?" source="attributes.is_adult" />
        <BooleanField label="Auto Categorized?" source="attributes.is_auto_categorized" />
        {canAccess({ permissions, action: 'list', resource: 'merchants' }) && (
          <ReferenceField
            label="Last Scan Time"
            source="relationships.last_scan.data.id"
            reference="scans"
          >
            <TzDateField source="attributes.end_date" showTime />
          </ReferenceField>
        )}
        <TzDateField label="Created At" source="attributes.created_at" showTime />
        <TzDateField label="Edited At" source="attributes.edited_at" showTime />
      </DatagridConfigurable>
    </List>
  );
}
