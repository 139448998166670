import { Create, TabbedForm } from 'react-admin';
import { MerchantResource } from '../../Entities/Merchant';
import SummaryTab from './Forms/SummaryTab';
import ContactInfoTab from './Forms/ContactInfoTab';
import AddressTab from './Forms/AddressTab';
import BillingInfoTab from './Forms/BillingInfoTab';
import MerchantConfigTab from './Forms/MerchantConfigTab';
import RemarksTab from './Forms/RemarksTab';

export default function MerchantCreate(): JSX.Element {
  return (
    <Create<MerchantResource>>
      <TabbedForm>
        <SummaryTab />
        <MerchantConfigTab />
        <AddressTab />
        <ContactInfoTab />
        <BillingInfoTab />
        <RemarksTab />
      </TabbedForm>
    </Create>
  );
}
