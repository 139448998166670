export const DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH = 50;
export const DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT = 50;
export const DEFAULT_LIST_PAGE_PAGINATION_LIMIT = 25;

export function isNumericString(value: unknown): value is string {
  return typeof value === 'string' && !Number.isNaN(value) && !Number.isNaN(parseFloat(value));
}

export type TruncationOptions = {
  omission?: string;
  locale?: string;
  granularity?: 'grapheme' | 'word' | 'sentence';
};
export function truncateString(
  value: string,
  length: number,
  options: TruncationOptions = {},
): string {
  if (value.length <= length) {
    return value;
  }

  const { omission = '...', locale, granularity = 'word' } = options;

  const segmenter = new Intl.Segmenter(locale, { granularity });
  const segments = [...segmenter.segment(value)];

  return segments.reduce((acc: string, segment: Intl.SegmentData) => {
    const currentLength = acc.length;
    const segmentLength = segment.segment.length;
    const omissionLength = omission.length;

    // If adding the segment would not exceed the length, add it
    if (
      currentLength + segmentLength <= length - omissionLength &&
      !acc.trimEnd().endsWith(omission)
    ) {
      return acc + segment.segment;
    }

    // If we've not already added the omission, add it
    if (!acc.trimEnd().endsWith(omission)) {
      return acc + omission;
    }

    // We can't add the segment and have already added the omission
    return acc.trimEnd();
  }, '');
}

export const omit = <T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> => {
  const result = { ...obj };
  keys.forEach((key) => delete result[key]);
  return result;
};

export function entry(value: string): { id: string; name: string } {
  return { id: value, name: value };
}

export function id<T>(value: T): T {
  return value;
}

export type DeepPartial<T> = T extends unknown[]
  ? T
  : T extends Record<string, unknown>
    ? {
        [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;
