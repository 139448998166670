import { Edit, TabbedForm } from 'react-admin';
import { ManufacturerResource } from '../../Entities/Manufacturer';
import KeywordsTab from './Forms/KeywordsTab';
import SummaryTab from './Forms/SummaryTab';
import EditToolbar from '../../Components/EditToolbar';

export default function ManufacturerEdit(): JSX.Element {
  return (
    <Edit<ManufacturerResource> mutationMode="pessimistic">
      <TabbedForm toolbar={<EditToolbar />}>
        <SummaryTab />
        <KeywordsTab />
      </TabbedForm>
    </Edit>
  );
}
