import SadPandaImg from '../sad_panda.png';

export default function SadPanda({
  ticket = undefined,
}: {
  ticket?: string;
  [key: string]: unknown;
}): JSX.Element {
  const name = ticket?.replace('https://purch1.atlassian.net/browse/', '');

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '[text-column] 400px [image-column] 200px',
        width: 600,
        height: 150,
      }}
    >
      <div style={{ gridColumn: 'text-column' }}>
        <h2>Sad Panda :(</h2>
        <p>Sorry, this feature is still under construction.</p>
        {ticket && (
          <p>
            Please refer to ticket <a href={ticket}>{name}</a> for more information.
          </p>
        )}
        {ticket === undefined && <p>Please check in with the dev team to set up a ticket.</p>}
      </div>
      <div style={{ gridColumn: 'image-column', height: 150 }}>
        <img src={SadPandaImg} alt="Sad Panda" style={{ height: '100%' }} />
      </div>
    </div>
  );
}
