import {
  fetchUtils,
  GetManyParams,
  GetManyResult,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import ExpiringCache from '../ExpiringCache';
import { defaultHeaders, withIncludes } from './utils';
import createFilter from './JsonApiFiltering';
import { JsonApiCollectionResponse, JsonApiResource } from './JsonApiTypes';
import { AuthProvider } from './AuthProvider';

export default function getMany(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  cache: ExpiringCache | undefined = undefined,
) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: GetManyParams,
  ): Promise<GetManyResult<T & JsonApiResource>> => {
    const ids: Identifier[] = [];
    const data: (T & JsonApiResource)[] = [];

    params.ids.forEach((id) => {
      const cacheKey = `${resource}/${id}`;
      const cached = cache?.get(cacheKey);

      if (cached) {
        data.push(cached as T & JsonApiResource);
      } else {
        ids.push(id);
      }
    });

    if (ids.length === 0) {
      return { data };
    }

    const permissions = await AuthProvider.getPermissions();
    const query = withIncludes(resource, permissions);
    const filter = createFilter({ id: ids });

    if (filter) {
      query.set('filter', filter);
    }

    query.set('page[size]', String(ids.length));

    const url = `${apiUrl}/${resource}?${query}`;

    try {
      const response = await httpClient(url, { headers: defaultHeaders, signal: params.signal });
      const result = response.json as JsonApiCollectionResponse;

      result.data?.forEach((d) => {
        cache?.set(`${resource}/${d.id}`, d);
        data.push(d as T & JsonApiResource);
      });

      if (result.included) {
        result.included.forEach((i) => {
          cache?.set(`${i.type}/${i.id}`, i);
        });
      }

      return { data };
    } catch (error) {
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error fetching records',
        (error as HttpError).body.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
