/**
 * The timezone of the server. This is used to convert timestamps from the API
 * to the user's local time. Defaults to Australia/Sydney as that is the
 * timezone our servers operate in. . This can be overridden by setting the
 * `SERVER_TIMEZONE` environment variable at build time.
 *
 * @constant {string} SERVER_TIMEZONE
 * @default 'Australia/Sydney'
 */
export const SERVER_TIMEZONE = process.env.SERVER_TIMEZONE ?? 'Australia/Sydney';

/**
 * The timezone of the user's browser.
 * @constant {string} LOCAL_TIMEZONE
 */
export const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * The available timezones for the user to select from. These must all be
 * either IANA timezones or the special value 'utc' for cross-compatibility
 * between the Intl API, luxon (used for timezone conversion and formatting TZ
 * strings), and the date-fns library (used for formatting dates and times in
 * input and display components).
 */
export const validTimezones = [
  { id: LOCAL_TIMEZONE, label: `Browser default (${LOCAL_TIMEZONE.replace(/_/g, ' ')})` },
  { id: SERVER_TIMEZONE, label: `System timezone (${SERVER_TIMEZONE.replace(/_/g, ' ')})` },
  { id: 'utc', label: 'UTC' },
  ...Intl.supportedValuesOf('timeZone')
    .map((tz) => ({ id: tz, label: tz.replace(/_/g, ' ') }))
    .filter((tz) => tz.id !== SERVER_TIMEZONE)
    .filter((tz) => tz.id !== LOCAL_TIMEZONE),
];

/**
 * The available date formats for the user to select from. These formats must
 * be compatible with the date-fns library.
 *
 * @see https://date-fns.org/v2.30.0/docs/format
 */
export const supportedDateFormats = [
  { id: 'dd/MM/y', label: 'AU/UK Format' },
  { id: 'MM/dd/y', label: 'US Format' },
  { id: 'd. M. y', label: 'CZ/SK Format' },
  { id: 'y‑MM‑dd', label: 'ISO-8601 Date' },
];

/**
 * The available time formats for the user to select from. These formats must
 * be compatible with the date-fns library.
 *
 * @see https://date-fns.org/v2.30.0/docs/format
 */
export const supportedTimeFormats = [
  { id: 'HH:mm:ss', label: '24-hour' },
  { id: 'h:mm:ss a', label: '12-hour' },
];

/**
 * The available timezone formats for the user to select from. These formats
 * must be compatible with the luxon library. The reason why we don't just use
 * the date-fns to format everything is that the date-fns library currently
 * does not support outputting anything beyond ISO offsets and GMT offsets. As
 * such, we're using luxon to format the timezone strings (in addition to doing
 * timezone conversions).
 *
 * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 */
export const supportedTimezoneFormats = [
  { id: 'ZZZZ', label: 'Short Timezone Name' },
  { id: 'ZZZZZ', label: 'Full Timezone Name' },
  { id: 'ZZ', label: 'UTC Offset' },
  { id: 'z', label: 'IANA Timezone Name' },
  { id: '', label: 'Not shown' },
];
