import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { Configurable, RaRecord, usePreference, useRecordContext, useTranslate } from 'react-admin';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { formatInTimeZone } from 'date-fns-tz';
import {
  SERVER_TIMEZONE,
  supportedDateFormats,
  supportedTimeFormats,
  supportedTimezoneFormats,
  validTimezones,
} from './utils';
import TzDatePreferencesEditor from './TzDatePreferencesEditor';

type TzDateFieldImplProps = TypographyProps & {
  source?: string;
  translateTimezone?: boolean;
  emptyText?: string;
  showDate?: boolean;
  showTime?: boolean;
};

function TzDateFieldImpl<Record extends RaRecord>({
  showDate = true,
  showTime = false,
  source,
  emptyText,
  translateTimezone = true,
  ...typographyProps
}: TzDateFieldImplProps): JSX.Element {
  const [timezone] = usePreference('timezone', validTimezones[0].id);
  const [dateFormat] = usePreference('format.date', supportedDateFormats[0].id);
  const [timeFormat] = usePreference('format.time', supportedTimeFormats[0].id);
  const [timezoneFormat] = usePreference('format.timezone', supportedTimezoneFormats[0].id);
  const record = useRecordContext<Record>();
  const translate = useTranslate();

  const emptyValue = (
    <Typography component="span" variant="body2" {...typographyProps}>
      {emptyText ? translate(emptyText, { _: emptyText }) : null}
    </Typography>
  );

  if (!source) {
    return emptyValue;
  }

  const serverTime = get(record, source);

  if (!serverTime) {
    return emptyValue;
  }

  let time = DateTime.fromISO(serverTime, { zone: SERVER_TIMEZONE });

  if (translateTimezone) {
    time = time.setZone(timezone);
  }

  const formatString = [showDate ? dateFormat : null, showTime ? timeFormat : null]
    .filter((f) => f !== null)
    .join(' ');

  const formattedTime = formatInTimeZone(time.toJSDate(), timezone, formatString);
  const timezoneString = timezoneFormat === '' ? '' : ` ${time.toFormat(timezoneFormat)}`;

  return (
    <Typography component="span" variant="body2" {...typographyProps}>
      <Tooltip
        title={
          formatInTimeZone(time.toJSDate(), timezone, `${dateFormat} ${timeFormat}`) +
          timezoneString
        }
      >
        <span>
          {formattedTime}
          {showTime ? timezoneString : null}
        </span>
      </Tooltip>
    </Typography>
  );
}

export type TzDateFieldProps = TzDateFieldImplProps & {
  preferenceKey?: string;
  label?: string;
};

export default function TzDateField<Record extends RaRecord>({
  preferenceKey = 'tzDatePreferences',
  ...rest
}: TzDateFieldProps): JSX.Element {
  return (
    <Configurable
      editor={<TzDatePreferencesEditor showDate={rest.showDate} showTime={rest.showTime} />}
      preferenceKey={preferenceKey}
    >
      <TzDateFieldImpl<Record> {...rest} />
    </Configurable>
  );
}
