import {
  BooleanField,
  BooleanInput,
  Pagination,
  ReferenceManyField,
  RichTextField,
  TabbedForm,
  TabbedFormViewProps,
  TextField,
  TextInput,
  required,
  useGetIdentity,
} from 'react-admin';
import {
  EditableDatagridConfigurable,
  RowFormConfigurable,
} from '@react-admin/ra-editable-datagrid';
import { useFormContext } from 'react-hook-form';
import TzDateField from '../../../Components/TzDate/TzDateField';

function RemarkForm(): JSX.Element | null {
  const { data, isLoading } = useGetIdentity();
  if (isLoading || typeof data === 'undefined') return null;

  const { getValues } = useFormContext();
  const { fullName } = data;

  return (
    <RowFormConfigurable
      defaultValues={{
        type: 'merchant_remarks',
        relationships: { merchant: { data: { type: 'merchants', id: getValues('id') } } },
      }}
    >
      <TzDateField label="Date" source="attributes.date" showTime />
      <BooleanInput label={false} source="attributes.status" defaultValue={false} />
      <TextInput
        label={false}
        fullWidth
        source="attributes.created_by"
        defaultValue={fullName}
        validate={required()}
      />
      <TextInput
        label={false}
        source="attributes.title"
        fullWidth
        multiline
        validate={required()}
      />
      <TextInput label={false} fullWidth source="attributes.comments" multiline />
      <RichTextField label={false} source="attributes.info" />
    </RowFormConfigurable>
  );
}

export default function RemarksTab(props: TabbedFormViewProps): JSX.Element {
  return (
    <TabbedForm.Tab label="Remarks" {...props}>
      <ReferenceManyField
        reference="merchant_remarks"
        target="merchant.id"
        sort={{ field: 'date', order: 'DESC' }}
        pagination={<Pagination />}
      >
        <EditableDatagridConfigurable
          bulkActionButtons={false}
          createForm={<RemarkForm />}
          editForm={<RemarkForm />}
          omit={['attributes.status']}
          sx={{
            '& .column-attributes\\.date': { maxWidth: 250 },
            '& .ra-input-attributes\\.date': { maxWidth: 250 },
            '& .ra-input-attributes\\.status': { minWidth: 100, maxWidth: 200 },
            '& .ra-input-attributes\\.created_by': { minWidth: 150, maxWidth: 400 },
            '& .column-attributes\\.title': { minWidth: 300, maxWidth: 500 },
            '& .ra-input-attributes\\.title': { minWidth: 300, maxWidth: 500 },
            '& .column-attributes\\.comments': { minWidth: 300, maxWidth: 500 },
            '& .ra-input-attributes\\.comments': { minWidth: 300, maxWidth: 500 },
            '& .column-attributes\\.info': { minWidth: 200, maxWidth: 300 },
            '& .ra-input-attributes\\.info': { minWidth: 200, maxWidth: 300 },
          }}
        >
          <TzDateField showTime label="Date" source="attributes.date" />
          <BooleanField label="Remark Closed?" source="attributes.status" />
          <TextField label="Author" source="attributes.created_by" />
          <RichTextField label="Title" source="attributes.title" />
          <RichTextField label="Comments" source="attributes.comments" />
          <RichTextField label="System Info" source="attributes.info" />
        </EditableDatagridConfigurable>
      </ReferenceManyField>
    </TabbedForm.Tab>
  );
}
