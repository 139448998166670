import { Title } from 'react-admin';
import { IfCanAccess } from '@react-admin/ra-rbac';
import MailCapStatusWidget from './DashboardWidgets/MailCapStatusWidget';
import LatestRemarksWidget from './DashboardWidgets/LatestRemarksWidget';
import LatestScansWidget from './DashboardWidgets/LatestScansWidget';

export default function Dashboard(): JSX.Element {
  return (
    <>
      <Title title="GetPrice CMS" />
      <br />
      <IfCanAccess resource="merchants" action="list">
        <MailCapStatusWidget />
      </IfCanAccess>
      <br />
      <IfCanAccess resource="merchants" action="list">
        <LatestRemarksWidget />
      </IfCanAccess>
      <br />
      <IfCanAccess resource="merchants" action="list">
        <LatestScansWidget />
      </IfCanAccess>
    </>
  );
}
