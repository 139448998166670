import get from 'lodash/get';
import { FunctionField, useRecordContext } from 'react-admin';

export default function TruncatedRichTextField({
  source,
  length = 150,
  label,
}: {
  source: string;
  length?: number;
  label?: string;
}): JSX.Element {
  const record = useRecordContext();
  return (
    <FunctionField
      label={label}
      render={() => {
        const content = get(record, source) ?? '';

        const truncatedContent =
          content.length > length ? `${content.substring(0, length)}...` : content;

        // eslint-disable-next-line react/no-danger
        return <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />;
      }}
    />
  );
}
