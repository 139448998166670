import { DeleteManyParams, DeleteManyResult, fetchUtils, Identifier, RaRecord } from 'react-admin';
import ExpiringCache from '../ExpiringCache';
import deleteFn from './delete';

export default function deleteMany(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  cache: ExpiringCache | undefined = undefined,
) {
  const del = deleteFn(apiUrl, httpClient, cache);

  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: DeleteManyParams<T>,
  ): Promise<DeleteManyResult<T>> => {
    await Promise.all(params.ids.map((id) => del(resource, { id })));

    return { data: params.ids };
  };
}
