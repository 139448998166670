import { fetchUtils, Identifier, RaRecord, UpdateManyParams, UpdateManyResult } from 'react-admin';
import ExpiringCache from '../ExpiringCache';
import update from './update';
import { JsonApiResource } from './JsonApiTypes';

export default function updateMany(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  cache: ExpiringCache | undefined = undefined,
) {
  const updateFn = update(apiUrl, httpClient, cache);

  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: UpdateManyParams<T>,
  ): Promise<UpdateManyResult<T & JsonApiResource>> => {
    await Promise.all(
      params.ids.map((id) =>
        updateFn(resource, {
          id,
          data: params.data,
          previousData: params.data,
        }),
      ),
    );

    return { data: params.ids.map(String) };
  };
}
