import { useAuthProvider, useRefresh, useStore } from 'react-admin';
import { Checkbox, Grid } from '@mui/material';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { AuthProvider, collapsePermissions } from '../DataProvider/AuthProvider';

export default function AssumedPermissionsPreferencesEditor(): JSX.Element {
  const authProvider = useAuthProvider<typeof AuthProvider>();
  const [seen, setSeen] = useStore<boolean>('preferences.assumedPermissionsSeen', false);
  const [retries, setRetries] = useState<number>(3);
  const [given, setGiven] = useStore<string[]>('permissions', []);
  const [assumed, setAssumed] = useStore<string[]>('preferences.assumedPermissions', []);
  const refresh = useRefresh();

  useEffect(() => {
    async function fetchPermissions(): Promise<void> {
      if (!authProvider) {
        return;
      }

      const basePermissions = collapsePermissions(
        await authProvider.getPermissions({ forceRefresh: true }),
      ).sort();

      if (basePermissions.length === 0) {
        // Highly unlikely, but if we get no permissions, retry a few times
        // before assuming the user has no permissions
        if (retries > 0) {
          setRetries(retries - 1);
          setTimeout(fetchPermissions, 50);
          return;
        }
      }

      if (!isEqual(basePermissions, given)) {
        setGiven(basePermissions);
      }

      // Find any revoked permissions and remove them from the assumed permissions
      const revoked = assumed.filter((p: string) => !given.includes(p));

      if (revoked.length > 0) {
        setAssumed(assumed.filter((p: string) => !revoked.includes(p)));
      }

      if (!seen) {
        setSeen(true);
        setAssumed(basePermissions);
      }
    }

    fetchPermissions();
  }, [authProvider, seen, given, assumed]);

  useEffect(refresh, [assumed]);

  function renderCheckbox(p: string): JSX.Element {
    return (
      <Grid item key={p} xs={12} xl={4}>
        <Checkbox
          checked={assumed.includes(p)}
          onChange={(e) => {
            if (e.target.checked) {
              setAssumed([...assumed, p]);
            } else {
              setAssumed(assumed.filter((ap: string) => ap !== p));
            }
          }}
        />
        {p}
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        {given.filter((p) => p === 'admin:api').map(renderCheckbox)}
      </Grid>
      <Grid container item xs={12} xl={6}>
        <Grid item xs={12}>
          <b>Read Permissions</b>
        </Grid>
        {given.filter((p) => p.startsWith('read')).map(renderCheckbox)}
      </Grid>
      <Grid container item xs={12} xl={6}>
        <Grid item xs={12}>
          <b>Write Permissions</b>
        </Grid>
        {given.filter((p) => p.startsWith('write')).map(renderCheckbox)}
      </Grid>
    </Grid>
  );
}
