import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Labeled,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { Box, Grid, InputAdornment } from '@mui/material';
import { ProductResource } from '../../Entities/Product';
import EditToolbar from '../../Components/EditToolbar';
import SadPanda from '../../Components/SadPanda';

export default function ProductCreate(): JSX.Element {
  return (
    <Create<ProductResource>>
      <TabbedForm toolbar={<EditToolbar />}>
        <TabbedForm.Tab label="Details">
          <Grid container spacing={2}>
            <Grid item xs={12} xl={4}>
              <Box flex={1}>
                <TextInput label="Name" source="attributes.name" fullWidth />
              </Box>
              <Box flex={1}>
                <TextInput label="Model" source="attributes.model" fullWidth />
              </Box>
              <Box flex={1}>
                <TextInput label="Manufacturer" source="attributes.manufacturer" fullWidth />
              </Box>
              <Box flex={1}>
                <TextInput
                  label="Description"
                  source="attributes.description"
                  multiline
                  fullWidth
                />
              </Box>
              <Box flex={1}>
                <TextInput label="Keywords" source="attributes.keywords" multiline fullWidth />
              </Box>
              <Box flex={1}>
                <ReferenceInput source="relationships.category.data.id" reference="categories">
                  <AutocompleteInput
                    label="Category"
                    optionText="attributes.full_name"
                    filterToQuery={(query: string) => ({ contains: { name: query } })}
                  />
                </ReferenceInput>
              </Box>
            </Grid>
            <Grid item xs={12} xl={2}>
              <NumberInput
                label="Min Price (AUD)"
                source="attributes.min_price"
                step={0.01}
                fullWidth
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              />
              <NumberInput
                label="Max Price (AUD)"
                source="attributes.max_price"
                step={0.01}
                fullWidth
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              />

              <NumberInput
                label="Future Publishing ID"
                source="attributes.future_publishing_id"
                fullWidth
              />
              <TextInput label="MPN" source="attributes.mpn" fullWidth />
              <TextInput label="UPC" source="attributes.upc" fullWidth />
            </Grid>
            <Grid item xs={12} xl={6}>
              <BooleanInput label="Active?" source="attributes.status" />
              <BooleanInput label="Is Prioritized?" source="attributes.is_prioritized" />
              <Labeled>
                <SadPanda
                  label="Upload New Image"
                  ticket="https://purch1.atlassian.net/browse/GET-672"
                />
              </Labeled>
            </Grid>
          </Grid>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Category Details">
          <ReferenceInput source="relationships.category.data.id" reference="categories">
            <AutocompleteInput
              label="Category"
              optionText="attributes.full_name"
              filterToQuery={(query: string) => ({ contains: { name: query } })}
            />
          </ReferenceInput>
          <SadPanda />
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
}
