import { Create, TabbedForm } from 'react-admin';
import { ManufacturerResource } from '../../Entities/Manufacturer';
import SummaryTab from './Forms/SummaryTab';
import KeywordsTab from './Forms/KeywordsTab';

export default function ManufacturerCreate(): JSX.Element {
  return (
    <Create<ManufacturerResource> redirect="edit">
      <TabbedForm>
        <SummaryTab />
        <KeywordsTab />
      </TabbedForm>
    </Create>
  );
}
