import { Edit } from 'react-admin';
import CategoryForm from './Form';

export default function CategoryEdit(): JSX.Element {
  return (
    <Edit>
      <CategoryForm />
    </Edit>
  );
}
