import {
  DatagridConfigurable,
  ImageField,
  List,
  NumberInput,
  TextField,
  TextInput,
  UrlField,
} from 'react-admin';
import TextArrayField from '../../Components/TextArrayField';
import { ManufacturerResource } from '../../Entities/Manufacturer';
import TruncatedRichTextField from '../../Components/TruncatedRichTextField';
import { DEFAULT_LIST_PAGE_PAGINATION_LIMIT } from '../../utils';

const filters = [
  <NumberInput key="filter.id" label="ID" source="equals.id" />,
  <TextInput key="filter.name" label="Name" source="contains.name" />,
  <TextInput key="filter.homepage" label="Homepage" source="contains.homepage" />,
  <TextInput key="filter.phone_number" label="Phone Number" source="contains.phone_number" />,
  <TextInput key="filter.description" label="Description" source="contains.description" />,
];

export default function ManufacturerList(): JSX.Element {
  return (
    <List<ManufacturerResource> perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT} filters={filters}>
      <DatagridConfigurable>
        <TextField source="id" />
        <TextField label="Name" source="attributes.name" />
        <UrlField label="Homepage" source="attributes.homepage" />
        <ImageField label="Logo" source="attributes.logo_url" />
        <TextField label="Phone Number" source="attributes.phone_number" />
        <TruncatedRichTextField label="Description" source="attributes.description" />
        <TextArrayField label="Keywords" source="attributes.keywords" />
      </DatagridConfigurable>
    </List>
  );
}
