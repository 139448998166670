import { canAccess } from '@react-admin/ra-rbac';
import {
  TabbedForm,
  TextInput,
  maxLength,
  ReferenceInput,
  SelectInput,
  required,
  TabbedFormViewProps,
  usePermissions,
} from 'react-admin';

export default function AddressTab(props: TabbedFormViewProps): JSX.Element {
  const { permissions } = usePermissions();
  return (
    <TabbedForm.Tab label="Address" {...props}>
      <TextInput label="Street Address" source="attributes.address" validate={maxLength(255)} />
      <ReferenceInput reference="states" source="relationships.state.data.id">
        <SelectInput label="State" optionText="attributes.name" />
      </ReferenceInput>
      <TextInput label="Post Code" source="attributes.postal_code" validate={maxLength(50)} />
      <TextInput label="City" source="attributes.city" validate={maxLength(50)} />
      {canAccess({ permissions, action: 'list', resource: 'regions' }) && (
        <ReferenceInput reference="regions" source="relationships.region.data.id">
          <SelectInput label="Region" optionText="attributes.name" validate={required()} />
        </ReferenceInput>
      )}
    </TabbedForm.Tab>
  );
}
