import {
  ImageField,
  Labeled,
  TabbedForm,
  TabbedFormViewProps,
  TextInput,
  maxLength,
} from 'react-admin';
import RichTextInput from '../../../Components/RichTextInput';
import SadPanda from '../../../Components/SadPanda';

export default function SummaryTab(props: TabbedFormViewProps): JSX.Element {
  return (
    <TabbedForm.Tab label="Summary" {...props}>
      <TextInput label="Name" source="attributes.name" validate={maxLength(50)} />
      <RichTextInput
        fullWidth
        label="Description"
        source="attributes.description"
        validate={maxLength(2000)}
      />
      <TextInput label="Homepage" source="attributes.homepage" validate={maxLength(400)} />
      <Labeled>
        <ImageField label="Logo" source="attributes.logo_url" />
      </Labeled>
      <Labeled>
        <SadPanda label="Upload New Logo" ticket="https://purch1.atlassian.net/browse/GET-672" />
      </Labeled>
      <TextInput label="Phone Number" source="attributes.phone_number" validate={maxLength(20)} />
    </TabbedForm.Tab>
  );
}
