import { Datagrid, EmailField, NumberField, SelectField, TextField } from 'react-admin';
import { MAIL_CAP_STATUS_CHOICES } from '../../Resources/Merchant/utils';
import ListWidget from './ListWidget';

export default function MailCapStatusWidget(): JSX.Element {
  return (
    <ListWidget
      title="Merchants near Budget Cap"
      resource="merchants"
      defaultSort={{ field: 'mail_cap_status_code,-monthly_budget', order: 'DESC' }}
      filter={{ any: { mail_cap_status_code: [1, 2] }, merchant_status: { id: '1' } }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Merchant Name" source="attributes.merchant_name" />
        <EmailField label="Primary contact email" source="attributes.email" />
        <NumberField
          label="Monthly Budget"
          source="attributes.monthly_budget"
          options={{ style: 'currency', currency: 'AUD' }}
        />
        <SelectField
          label="Cap Status"
          source="attributes.mail_cap_status_code"
          choices={MAIL_CAP_STATUS_CHOICES}
        />
      </Datagrid>
    </ListWidget>
  );
}
