import { Datagrid, NumberField, TextField } from 'react-admin';
import TzDateField from '../TzDate/TzDateField';
import ListWidget from './ListWidget';

export default function LatestScansWidget(): JSX.Element {
  return (
    <ListWidget resource="scans" title="Latest Scans">
      <Datagrid bulkActionButtons={false}>
        <TzDateField showDate showTime label="Start Date" source="attributes.start_date" />
        <TzDateField showDate showTime label="End Date" source="attributes.end_date" />
        <TextField label="Initiated By" source="attributes.initiated_by" />
        <NumberField label="Total Shops" source="attributes.total_shops" />
        <NumberField label="Successful Shops" source="attributes.successful_shops" />
        <NumberField label="Minor Issue Shops" source="attributes.minor_issue_shops" />
        <NumberField label="Major Issue Shops" source="attributes.major_issue_shops" />
        <NumberField label="Unsuccessful Shops" source="attributes.unsuccessful_shops" />
      </Datagrid>
    </ListWidget>
  );
}
