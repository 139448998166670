import {
  AutocompleteArrayInput,
  BooleanField,
  DatagridConfigurable,
  ImageField,
  List,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import {
  DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT,
  DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH,
  DEFAULT_LIST_PAGE_PAGINATION_LIMIT,
} from '../../utils';

const filters = [
  <TextInput alwaysOn key="id" label="ID" source="id" />,
  <TextInput alwaysOn key="name" label="Name" source="contains.name" />,
  <ReferenceArrayInput
    key="parent_id"
    label="Parent Category"
    source="parent.id"
    reference="categories"
  >
    <AutocompleteArrayInput
      label="Parent Category"
      optionText="attributes.name"
      filterToQuery={(query: string) =>
        query.match(/^\d+$/)
          ? { or: { equals: { id: query }, contains: { name: query } } }
          : { contains: { name: query } }
      }
    />
  </ReferenceArrayInput>,
  <NullableBooleanInput key="is_root" label="Is Root Category?" source="isNull.parent" />,
  <NumberInput key="min_price_lt" label="Min Price Less Than" source="lessThan.min_price" />,
  <NumberInput key="min_price_gt" label="Min Price Greater Than" source="greaterThan.min_price" />,
  <NullableBooleanInput key="is_promoted" label="Promoted?" source="is_promoted" />,
  <NullableBooleanInput key="is_active" label="Active?" source="is_active" />,
  <NullableBooleanInput key="is_adult_content" label="Adult Content?" source="is_adult_content" />,
  <NullableBooleanInput
    key="is_offer_comparison_allowed"
    label="Offer Comparison Allowed?"
    source="is_offer_comparison_allowed"
  />,
  <TextInput key="exclude_contains" label="Excludes Contain" source="contains.exclude" />,
  <TextInput
    key="exclude_not_contains"
    label="Excludes Do Not Contain"
    source="not.contains.exclude"
  />,
];

export default function CategoryList(): JSX.Element {
  return (
    <List perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT} filters={filters}>
      <DatagridConfigurable
        omit={[
          'attributes.dictionary',
          'attributes.exclude',
          'attributes.fixed_name',
          'attributes.footer_links',
          'attributes.full_name',
          'attributes.is_active',
          'attributes.is_adult_content',
          'attributes.keywords',
          'attributes.old_name',
          'attributes.search_keywords',
          'attributes.singularized_name',
          'attributes.word_rank',
        ]}
      >
        <TextField source="id" />
        <ImageField
          label="Image"
          sortable={false}
          source="attributes.image_url"
          sx={{
            '& img': {
              maxHeight: DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT,
              maxWidth: DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH,
            },
          }}
        />
        <ImageField
          label="Icon"
          sortable={false}
          source="attributes.icon_url"
          sx={{
            '& img': {
              maxHeight: DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT,
              maxWidth: DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH,
            },
          }}
        />
        <TextField label="Name" source="attributes.name" />
        <TextField label="Full Path" sortable={false} source="attributes.full_name" />
        <TextField label="Old Name" source="attributes.old_name" />
        <TextField label="Fixed Name" source="attributes.fixed_name" />
        <TextField label="Singularized Name" source="attributes.singularized_name" />
        <TextField label="Dictionary" source="attributes.dictionary" />
        <TextField label="Keywords" source="attributes.keywords" />
        <TextField label="Search Keywords" source="attributes.search_keywords" />
        <TextField label="Exclude" source="attributes.exclude" />
        <ReferenceField
          label="Parent Category"
          source="relationships.parent.data.id"
          reference="categories"
        >
          <TextField source="attributes.name" />
        </ReferenceField>
        <NumberField
          label="Min Price"
          source="attributes.min_price"
          options={{ style: 'currency', currency: 'AUD' }}
        />
        <BooleanField label="Promoted?" source="attributes.is_promoted" />
        <BooleanField label="Active?" source="attributes.is_active" />
        <BooleanField label="Adult Content?" source="attributes.is_adult_content" />
        <BooleanField
          label="Offer Comparison Allowed?"
          source="attributes.is_offer_comparison_allowed"
        />
      </DatagridConfigurable>
    </List>
  );
}
